import firebase from 'gatsby-plugin-firebase';
import React, { useContext, useCallback, useLayoutEffect } from 'react';
import { useState, createContext, useMemo } from 'react';

interface State {
  user: firebase.User;
}
interface Updater {
  setUser: (user: firebase.User) => void;
}

const UserStateContext = createContext<State | undefined>(undefined);
const UserUpdaterContext = createContext<Updater | undefined>(undefined);

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);

  const onSetUser = useCallback((user: firebase.User) => {
    setUser(user);
  }, []);

  useLayoutEffect(() => {
    // firebase.aujth().signInWithCredential
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then();
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  const stateContext = useMemo<State>(() => ({ user: user! }), [user]);
  const updaterContext = useMemo<Updater>(() => ({ setUser: onSetUser }), [onSetUser]);

  return (
    <UserStateContext.Provider value={stateContext}>
      <UserUpdaterContext.Provider value={updaterContext}>
        {children}
      </UserUpdaterContext.Provider>
    </UserStateContext.Provider>
  );
}

const useUserState = (): State => {
  const userState = useContext(UserStateContext);

  if (typeof userState === 'undefined') {
    // throw new Error('userState must be used within a UserProvider');
    console.warn('userState must be used within a UserProvider');
    return {} as State;
  }

  return userState;
};

const useUserUpdater = (): Updater => {
  const userUpdaterContext = useContext(UserUpdaterContext);

  if (typeof userUpdaterContext === 'undefined') {
    throw new Error('userUpdater must be used within a UserProvider');
  }

  return userUpdaterContext;
};

export {
  UserProvider,
  useUserState,
  useUserUpdater,
};
