/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'firebase/firestore';
import 'firebase/auth';

import React from "react"
import { UserProvider } from './src/context/UserContext';

export const wrapRootElement = ({ element }) => (
  <UserProvider>{element}</UserProvider>
);
