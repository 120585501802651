// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-amafu-tsx": () => import("./../../../src/pages/about/amafu.tsx" /* webpackChunkName: "component---src-pages-about-amafu-tsx" */),
  "component---src-pages-about-jikelele-tsx": () => import("./../../../src/pages/about/jikelele.tsx" /* webpackChunkName: "component---src-pages-about-jikelele-tsx" */),
  "component---src-pages-about-members-tsx": () => import("./../../../src/pages/about/members.tsx" /* webpackChunkName: "component---src-pages-about-members-tsx" */),
  "component---src-pages-about-omnotho-tsx": () => import("./../../../src/pages/about/omnotho.tsx" /* webpackChunkName: "component---src-pages-about-omnotho-tsx" */),
  "component---src-pages-admin-contributions-tsx": () => import("./../../../src/pages/admin/contributions.tsx" /* webpackChunkName: "component---src-pages-admin-contributions-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-registrations-tsx": () => import("./../../../src/pages/admin/registrations.tsx" /* webpackChunkName: "component---src-pages-admin-registrations-tsx" */),
  "component---src-pages-generic-tsx": () => import("./../../../src/pages/generic.tsx" /* webpackChunkName: "component---src-pages-generic-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-amafu-tsx": () => import("./../../../src/pages/register/amafu.tsx" /* webpackChunkName: "component---src-pages-register-amafu-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-submit-success-tsx": () => import("./../../../src/pages/submit-success.tsx" /* webpackChunkName: "component---src-pages-submit-success-tsx" */)
}

